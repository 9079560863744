body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#background {
  background-image: url("./img/Background.jpg"); 
  position: absolute; 
  width: 100%; 
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

#screen {
  position: relative; 
  width: 100%; 
  height: 100%;
  z-index: -1;
}


@font-face {
  font-family: "Metropolis";
  src: url("./font/Metropolis/Metropolis-ExtraLight.ttf");
}
@font-face {
  font-family: "Metropolis";
  src: url("./font/Metropolis/Metropolis-SemiBold.ttf");
  font-weight: bold;
}

#fader {
  width: 100%; 
  height: 100%;
  position: relative;
  justify-content: center;
}

.text {
  top: 8%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;  
  position: relative;

  font-family: 'Metropolis', sans-serif;
  font-size: calc(6px + (300 - 6) * ((100vmax + 100vmin - 384px) / (7680 + 7680 - 384)));

  color: white;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  cursor: default;
}

#text2 {
  top: 33%;
  margin-left: 2%;
  margin-right: 2%;
  text-align: center;  
  position: relative;

  font-family: 'Metropolis', sans-serif;
  font-size: calc(7px + (300 - 7) * ((100vmax + 100vmin - 384px) / (7680 + 7680 - 384)));

  color: white;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  cursor: default;
}

.fade-wrapper {
  transition:opacity ease-in-out;
}
.fade-out {
  opacity:0;  
}
.fade-in {
  opacity:1;
}

#logo {
  display: flex;
  position: relative;
  justify-content: center;
}

.logoImg {
  margin-top: 5%;
  width: calc(50px + (1250 - 50) * ((100vmax + 100vmin - 384px) / (7680 + 7680 - 384)));
}

.maximizable-actions {
  position: absolute;
  right: 1%;
  top: 1%;
}

.audio-icons {
  position: absolute;
  left: 1%;
  top: 1%;
}

.fullscreen-button {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fullscreen-button-img {
  width: calc(8px + (200 - 8) * ((100vmax + 100vmin - 384px) / (7680 + 7680 - 384)));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.audio-button {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.audio-button-img {
  width: calc(8px + (200 - 8) * ((100vmax + 100vmin - 384px) / (7680 + 7680 - 384)));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.instagram-icon {
  position: absolute;
  left: 1%;
  bottom: 1%;
}

.instagram-button {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.instagram-button-img {
  width: calc(8px + (200 - 8) * ((100vmax + 100vmin - 384px) / (7680 + 7680 - 384)));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}